function SubmitFormController(jobSubmit, Uploader, genomeFactory, userProfile,
  $scope, $log, $mdDialog, $location, $http, $q, ExperimentUploader, SETTINGS) {

  this.setState = () => {
    this.formState = jobSubmit.state;
    this.parentJob = jobSubmit.parentJob;
  };

  this.$onInit = () => {
    this.uploader = Uploader;
    this.genomes = genomeFactory.genomes;
    this.files = [];
    this.experimentNames = [];
    this.tsvHeaders = ["Experiment Name", "Sample ID", "Subject ID"];

    if (!jobSubmit.hasParentJob()) {
      jobSubmit.instanceParentJob();
    }

    if (this.dataToPass) {
      this.selectedExperiment = this.dataToPass;
      this.searchText = this.selectedExperiment;
      this.selectedItemChange(this.searchText);
    }

    this.setState();
  };

  this.resetEmailState = function resetEmailState() {
    jobSubmit.state.emailSet = false;
  };

  this.resetOptionsState = function resetOptionsState() {
    jobSubmit.state.optionsSet = false;
  };

  this.resetExperimentState = () => {
    this.cancelUpload();
  };

  this.markOptionsSet = function markOptionsSet() {
    jobSubmit.state.optionsSet = true;
  };

  this.markEmailSet = function markEmailSet() {
    jobSubmit.state.emailSet = true;
  };

  this.markExperimentSet = function markExperimentSet() {
    jobSubmit.state.experimentSet = true;

    if (!this.selectedExperiment && (!this.files || this.files.length === 0) && this.searchText) {
      jobSubmit.setExperiment(this.searchText);
    } else {
      jobSubmit.setExperiment(this.selectedExperiment);
    }
  };

  this.reset = () => {
    if (!jobSubmit.parentJob.submitted && jobSubmit.parentJob.optionsSet) {
      // clears all state
      jobSubmit.clearProgress();
      return;
    }

    jobSubmit.instanceParentJob();

    this.setState();
  };

  $scope.$on(Uploader.allUploadedEvent, (event, jobs) => {
    if (!jobs) {
      // Upload was cancelled
      return;
    }

    const firstJob = jobs[0];

    this.reset();

    $location.path('/queue/all').search({ _id: firstJob._id });
  });

  this.previewFile = (files) => {
    if (!files || !files.length) {
      return;
    }

    if (files.length > 1) {
      alert("Can only upload one file at a time.");
      this.files = [];
      return;
    }

    const file = files[0];
    const reader = new FileReader();
    let delimiter = '\t';
    let headerColumns = [];
    let experimentName = '';
    let lines;
    this.selectedExperiment = '';
    this.searchText = '';
    this.confirmationChecked = false;

    reader.onload = (e) => {
      const contents = e.target.result;

      this.tsvData = [];
      this.displayTsvData = [];

      const experimentNames = new Set();
      const maxRowsToDisplay = 10;

      if (file.name.endsWith('.csv') || file.name.endsWith('.tsv')) {
        if (contents) {
          lines = contents.split('\n');
          if (lines.length < 2) {
            $log.error('The file appears to be empty or does not have header data.');
            return;
          }
          if (file.name.endsWith('.csv')) {
            delimiter = ',';
          }

          headerColumns = lines[0].split(delimiter).map(str => str.trim());
          const requiredHeaders = ["Experiment Name", "Sample ID", "Subject ID"];
          const hasRequiredHeaders = requiredHeaders.every(header => headerColumns.includes(header));

          if (!hasRequiredHeaders) {
            alert("Your file is missing one or more of the required headers: Experiment Name, Sample ID, Subject ID.");
            return;
          }

          this.tsvHeaders = headerColumns;
          experimentName = headerColumns[0];

          for (let i = 1; i < lines.length; i++) {
            if (lines[i].trim() === '') {
              continue;
            }
            const columns = lines[i].split(delimiter).map(str => str.trim());
            this.tsvData.push(columns);
            experimentNames.add(columns[0]);

            if (i <= maxRowsToDisplay) {
              this.displayTsvData.push(columns);
            } else if (i === maxRowsToDisplay + 1 && lines.length > maxRowsToDisplay + 1) {
              if (lines.length === maxRowsToDisplay + 2) {
                this.displayTsvData.push(columns);
              } else {
                this.displayTsvData.push(['...', '...', '...']);
              }
            } else if (i === lines.length - 1 && lines.length > maxRowsToDisplay + 2) {
              this.displayTsvData.push(columns);
            }
          }

          ExperimentUploader.experimentName = experimentName;
        }
      }

      if (experimentNames.size === 1) {
        this.selectedExperiment = [...experimentNames][0];
        this.searchText = this.selectedExperiment;
      }
      this.experimentNames = [...experimentNames];

      $scope.$apply();
    };

    reader.readAsText(file);
  };

  this.selectedItemChange = function (item) {
    // If the user has selected a file for upload and is now previewing it
    // we don't want to trigger fetching logic
    if (this.files && this.files.length > 0) {
      if (!item) {
        return this.populateTable(this.tsvData);
      }

      return this.populateTable(this.tsvData.filter((row) => row[0] === item));
    }

    if (!item) {
      this.tsvData = [];
      this.displayTsvData = [];
      this.experimentNames = [];
      this.confirmationChecked = false;
      jobSubmit.clearExperiment();
      return;
    }

    if (this.experimentNames.includes(item)) {
      this.populateTable(this.tsvData.filter((row) => row[0] === item));
    } else {
      this.fetchExperimentDetails(item).then(data => {
        this.tsvData = data;
        this.populateTable(data);
      });
    }
  };

  this.populateTable = function (data) {
    this.displayTsvData = [];

    const maxRowsToDisplay = 10;

    if (!data) {
      // currently sends warning if no data is being populated, but could implement loading bar while file is being read from reader.readAsText(file);
      $log.warn('No data available to populate the table, the file could still be loading');
      return;
    }

    for (let i = 0; i < data.length; i++) {
      if (i < maxRowsToDisplay) {
        this.displayTsvData.push(data[i]);
      } else if (i === maxRowsToDisplay && data.length > maxRowsToDisplay + 1) {
        this.displayTsvData.push(['...', '...', '...']);
        i = data.length - 2;
      } else if (i === data.length - 1) {
        this.displayTsvData.push(data[i]);
      }
    }
  };

  this.cancelUpload = () => {
    this.files = [];
    this.selectedExperiment = '';
    this.searchText = '';
    this.confirmationChecked = false;
    this.tsvData = [];
    this.displayTsvData = [];
    this.experimentNames = [];
    jobSubmit.clearExperiment();
    ExperimentUploader.cancelUpload();
  };

  this.cancelAllUploads = () => {
    this.files = [];
  };

  this.isUploading = () => {
    return ExperimentUploader.running;
  };

  this.isFailed = () => {
    return ExperimentUploader.file && ExperimentUploader.file.serverError;
  };

  this.uploadFiles = () => {
    if (!this.parentJob) {
      console.error("Something went wrong; parentJob is falsy.");
      return;
    }

    if (!(this.confirmationChecked && this.selectedExperiment && this.files.length > 0)) {
      alert("Please uncheck the confirmation box, and ensure you have selected an experiment before uploading files.");
      return;
    }

    if (this.files.length != 1) {
      alert("Currently only one file is supported. Please ensure only 1 file selected.");
      return;
    }

    // User has already seen the below alert, they are forcing this through
    if (this.files[0].serverError) {
      this.markExperimentSet();
      return;
    }

    ExperimentUploader.upload(this.files[0], this.parentJob, this.selectedExperiment)
      .then(() => {
        if (this.files[0].serverError) {
          alert(`${this.files[0].serverError}\nPlease clear the file or click 'Next' again to skip upload`);
        } else {
          this.markExperimentSet();
          this.onComplete(jobSubmit.getExperiment());
          $mdDialog.hide();
        }
      })
      .catch((error) => {
        $log.error(error);
        alert(`${this.files[0].serverError}\nPlease clear the file or click 'Next' again to skip upload`);
      });
  };

  this.querySearch = function (query) {
    if (!query) {
      return this.experimentNames;
    }

    if (this.files && this.files.length > 0) {
      return this.experimentNames.filter(function (name) {
        return name.toLowerCase().includes(query.toLowerCase());
      });
    }
    else {
      const deferred = $q.defer();

      $http.get(SETTINGS.apiEndpoint + 'jobs/searchExperiment', { params: { term: query } })
        .then(function (response) {
          deferred.resolve(response.data);
        })
        .catch(function () {
          deferred.reject([]);
        });
      return deferred.promise;
    }
  };

  this.fetchExperimentDetails = function (experimentName) {
    return $http.get(SETTINGS.apiEndpoint + 'jobs/getExperimentDetails', {
      params: {
        experimentName: experimentName,
        limit: 11,
      }
    })
      .then((response) => {
        if (!response.data.data || !response.data.data.length) {
          return [];
        }

        const tsvResponseHeaders = Object.keys(response.data.data[0]).filter(key => key !== 'covariates');

        let covariateHeaders = [];
        if (response.data.data[0].covariates && Object.keys(response.data.data[0].covariates).length > 0) {
          covariateHeaders = Object.keys(response.data.data[0].covariates);
        }
        this.tsvHeaders = tsvResponseHeaders.concat(covariateHeaders);

        const rowData = response.data.data.map(row => {
          const Data = this.tsvHeaders.map(header => {
            if (covariateHeaders.includes(header) && row.covariates) {
              return row.covariates[header] || '';
            }
            return row[header];
          });
          return Data;
        });

        return rowData;
      })
      .catch((error) => {
        $log.error('Error fetching experiment details:', error);
      });
  };

  this.closeDialogCancel = function () {
    $mdDialog.hide();
  };

  this.closeDialogOk = function () {
    this.markExperimentSet();
    this.onComplete(jobSubmit.getExperiment());
    $mdDialog.hide();
  };

}

angular.module('sq.jobs.forms.submitForm.component', [
  'sq.jobs.upload.component',
  'sq.jobs.submit.service',
  'sq.jobs.forms.services.genome',
  'sq.jobs.upload.service',
  'sq.user.profile.service',
  'sq.jobs.experiment.upload.service',
])
  .component('sqJobSubmitForm', {
    templateUrl: 'jobs/submit-form/jobs.submit-form.tpl.html',
    controller: SubmitFormController,
  });